import { Heading, VStack, Image } from "@chakra-ui/react";
import React, { FC } from "react";
import { Link } from "react-router-dom";

export const Sponsor: FC<{
    image: string;
    name: string;
    url: string;
}> = ({ image, name, url }) => {

    if (url.includes("http")) {
        return (
            <a href={url} target="blank">
                <VStack>
                    <Image width="100%" src={image} />
                    <Heading size="sm">{name}</Heading>
                </VStack>
            </a>
        )
    } else {
        return (
            <Link to={url} href={url}>
                <VStack>
                    <Image src={image} />
                    <Heading size="sm">{name}</Heading>
                </VStack>
            </Link>
        )
    }
}
