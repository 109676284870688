import { Badge, Center, Flex, Grid, VStack } from "@chakra-ui/layout"
import {
    Box,
    Button,
    Heading,
    HStack,
    Image,
    Input,
    Switch,
    Tag,
    Text,
    useMediaQuery,
    useToast,
} from "@chakra-ui/react"
import React, { FC, useContext, useEffect, useState } from "react"
import { FaTv } from "react-icons/fa"
import { apiCall } from "../../util/api-helpers"
import { setUid } from "../../util/state-helpers"
import config from "../../config.json"
import { stores } from "../../styled/stores"
import { observer } from "mobx-react-lite"
import moment from "moment"
import { getTimeRemaining } from "../../util/time-helpers"
import { isReturnStatement } from "typescript"

let interval: NodeJS.Timeout
let autoMove = true

export const Signup: FC<{
    wsConnect: Function
}> = observer(({ wsConnect }) => {
    const [loading, setLoading] = useState(false)
    const [connected, setConnected] = useState(false)
    const [userReceived, setUserReceived] = useState(false)
    const toast = useToast()
    const chatStore = useContext(stores.chatStore)
    const [timeR, setTimeR] = useState(getTimeRemaining(config.misc.eventStart))
    const [name, setUsernameValue] = useState(chatStore.user?.name || "")
    const [org, setOrgValue] = useState(chatStore.user?.org || "")
    const [ordNr, setOrdNr] = useState(chatStore.user?.ordNr || "")
    const [smallScreen] = useMediaQuery("(max-height: 720px)")
    async function init() {
        const result = await apiCall({ url: "/", method: "GET" })
        if (result && result.status === "ready") setConnected(true)
        clearInterval(interval)
        interval = setInterval(function () {
            if (
                getTimeRemaining(config.misc.eventStart).total <= 0 &&
                chatStore.user &&
                chatStore.interacted
            ) {
                clearInterval(interval)

                if (autoMove) {
                    chatStore.setNameConfirmed()
                    wsConnect()
                }
                setTimeR(getTimeRemaining(config.misc.eventStart))

                return
            }
            setTimeR(getTimeRemaining(config.misc.eventStart))
        }, 500)
    }

    const signUp = async () => {
        setLoading(true)
        const result = await apiCall({
            url: chatStore.user ? "/update-name" : "/register",
            method: "POST",
            body: { name, org, ordNr },
        })
        setLoading(false)
        if (result && result.hasOwnProperty("name")) {
            setUserReceived(true)
            setUid(result.userId)
            toast({
                title: chatStore.user ? "Welcome back!" : "Welcome!",
                description:
                    timeR.total <= 0
                        ? "You are being moved to the event right now"
                        : "Sit tight!, once the event starts you will be moved",
                status: "success",
                duration: 3000,
                isClosable: true,
            })
            chatStore.setUser(result)

            setTimeout(() => {
                chatStore.setInteracted()

                if (timeR.total <= 0) {
                    clearInterval(interval)
                    chatStore.setNameConfirmed()
                    wsConnect()
                }
            }, 1000)
        } else {
            toast({
                title: "Oh no something went wrong",
                description: "Please try again, something went wrong...",
                status: "error",
                duration: 5000,
                isClosable: true,
            })
            setLoading(false)
        }
    }

    useEffect(() => {
        init()
    }, [])

    return (
        <Center
            background={config.branding.colors.main.backgroundColor}
            h={smallScreen ? "initial" : "100vh"}
            backgroundImage='url(/bg-min.png)'
            backgroundSize='cover'
        >
            <VStack margin='5' spacing='5' background='white' padding='20px' borderRadius='5px'>
                <HStack>
                    <Flex flexWrap='wrap' align='center'>
                        {config.branding.logos.map((logo) => {
                            return <Image maxH='12' src={logo} />
                        })}
                    </Flex>

                    {/* <Image maxH="8" src={config.branding.logo} /> */}
                    {/* <Tag
                        borderRadius={"48px"}
                        style={{ marginLeft: "15px" }}
                        paddingX={3}
                        paddingY={2}
                        color={config.branding.colors.main.color}
                        background={config.branding.colors.schemes.green}
                    >
                        {config.branding.eventType}
                    </Tag> */}
                </HStack>
                <Heading maxW='350px' textAlign='center' size='md'>
                    {config.branding.tagLine}
                </Heading>
                <Text maxW='300px' textAlign='center'>
                    {config.branding.description}
                </Text>

                <Box
                    borderRadius='5'
                    border='1px'
                    borderColor='blackAlpha.200'
                    p='4'
                    w='100%'
                    textAlign='center'
                >
                    <Heading size='sm'>
                        {timeR.total <= 0 ? (
                            "Event started"
                        ) : (
                            <>
                                Starts in
                                {timeR.days > 0
                                    ? timeR.days === 1
                                        ? " 1 day, "
                                        : ` ${timeR.days} days,`
                                    : ""}
                                {timeR.hours > 0
                                    ? timeR.hours === 1
                                        ? " 1 hour and "
                                        : ` ${timeR.hours} hours and`
                                    : ""}
                                {timeR.minutes > 0
                                    ? timeR.minutes === 1
                                        ? " 1 minute"
                                        : ` ${timeR.minutes} minutes`
                                    : ""}
                                {timeR.hours === 0 && timeR.days === 0 && timeR.minutes === 0
                                    ? ` ${
                                          timeR.seconds === 1
                                              ? "1 second"
                                              : `${timeR.seconds} seconds`
                                      }`
                                    : ""}
                            </>
                        )}
                    </Heading>
                    <Text fontSize='xs' fontWeight='medium' mt='2' color='GrayText'>
                        {moment(config.misc.eventStart).format("HH:mm, DD MMMM YYYY")}
                    </Text>
                </Box>

                <Input
                    defaultValue={chatStore.user?.name}
                    disabled={loading}
                    variant='filled'
                    onChange={(e) => setUsernameValue(e.target.value)}
                    placeholder='Full name'
                />
                {/* <Input
                    defaultValue={chatStore.user?.org}
                    disabled={loading}
                    variant='filled'
                    onChange={(e) => setOrgValue(e.target.value)}
                    placeholder='Organisation'
                />
                <Input
                    defaultValue={chatStore.user?.ordNr}
                    disabled={loading}
                    variant='filled'
                    onChange={(e) => setOrdNr(e.target.value)}
                    placeholder='Order number (optional)'
                /> */}

                {timeR.total > 0 ? (
                    <Switch
                        display='flex'
                        alignItems='center'
                        w='100%'
                        onChange={(e) => {
                            autoMove = e.target.checked
                        }}
                        defaultChecked
                    >
                        Automatically move me when event starts
                    </Switch>
                ) : null}
                <Button
                    w='100%'
                    _hover={config.branding.colors.button}
                    variant='miles'
                    disabled={name.length < 3 || loading || name.length > 100}
                    isLoading={loading}
                    onClick={signUp}
                    rightIcon={<FaTv />}
                >
                    {timeR.total <= 0
                        ? "Watch event"
                        : userReceived
                        ? "Update name"
                        : chatStore.user
                        ? "Watch event"
                        : "Join now"}
                </Button>
            </VStack>
        </Center>
    )
})
