import { Center, VStack } from "@chakra-ui/layout"
import { Heading, Spinner, Text } from "@chakra-ui/react"
import React from "react"

export const Loading = () => {

    return (
        <Center bg="white" h="100vh">
            <VStack spacing="5">
                <Heading>Loading platform</Heading>
                <Text>This shouldn't take too long</Text>
                <Spinner size="xl" />
            </VStack>
        </Center>
    )
}