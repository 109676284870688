import { extendTheme } from "@chakra-ui/react"
import config from "./config.json";

const theme = extendTheme({

    components: {

        Button: {
            variants: {
                miles: config.branding.colors.button
            }
        },
    },

    shadows: {
        outline: "none !important"
    }
})

export default theme;