import { useEffect, useState } from "react";
import { apiCall, Request } from "../util/api-helpers";

export const useApiCall = (request: Request, loadingState: boolean = true, callback?: Function) => {

    const [loading, setLoading] = useState(loadingState);
    const [data, setData] = useState<any | null>(null);

    const fetchData = async () => {
        const result = await apiCall(request);
        setData(result);
        setLoading(false);
        if (callback)
            callback(result);
    };

    useEffect(() => {
        fetchData();
    }, []);

    return { data, loading, fetchData };
};