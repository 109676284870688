import { Badge, Circle, Text } from "@chakra-ui/react";
import { observer } from "mobx-react-lite";
import React, { useContext } from "react";
import { FaEye } from "react-icons/fa";
import { stores } from "../../styled/stores";

export const ViewerCount = observer(() => {
    const store = useContext(stores.chatStore);
    if (store.user?.role === 0) return null
    return (
        <Badge display="flex" alignItems="center" position="absolute" top="10px" left="10px" >
            <Circle bg="red" size="5px" mr="5px" />
                            LIVE <Text ml="7.5px">{store.streamStats.viewerCount}</Text>
            <FaEye style={{ marginLeft: 5 }} />
        </Badge>
    )
})
