export const loremXL = `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc egestas varius sem eleifend tempor. Suspendisse sed mi viverra, fringilla velit vel, tincidunt metus. Cras hendrerit lacus eu nibh cursus, quis dapibus neque hendrerit. Suspendisse a bibendum lacus. Pellentesque congue sapien in nunc euismod, sed malesuada nisl mattis. Vestibulum fringilla sem mauris, sed sollicitudin dui ornare a. Maecenas ultrices aliquam turpis in cursus. Maecenas iaculis, nulla interdum venenatis rhoncus, nisl risus condimentum lacus, nec hendrerit tortor purus eu odio. Integer a nulla fermentum, iaculis felis nec, volutpat ligula. Praesent cursus imperdiet ante, ut eleifend ex laoreet vel. Quisque accumsan elit sed urna mollis, sed mattis lorem rutrum. Nullam auctor ex odio, ac feugiat purus faucibus varius. Aliquam metus leo, malesuada in est ut, feugiat fermentum libero.

Nulla vel aliquam lorem, a suscipit nulla. Nulla non aliquet leo. Aliquam placerat ornare lectus. Donec maximus volutpat diam varius euismod. Integer rhoncus luctus velit, eget semper ante ultrices sit amet. Phasellus eros urna, tristique in ultricies eget, tristique finibus enim. Pellentesque in lacinia augue. Quisque sit amet justo sagittis, maximus sem ut, accumsan justo. Vestibulum vitae pharetra velit, eget mollis quam.

Maecenas dictum sollicitudin pellentesque. Mauris semper, orci vitae mattis lobortis, diam metus blandit risus, vestibulum sollicitudin mauris neque eu ligula. Etiam in odio laoreet, interdum quam ac, vehicula nulla. Aliquam sed tristique erat, id dignissim arcu. Vestibulum convallis, ex eu efficitur interdum, dui lorem luctus ligula, euismod convallis tellus metus sed ipsum. Morbi mattis, lacus nec porttitor lobortis, nunc urna ultricies magna, tempor lacinia nunc ipsum ut tortor. Nulla vehicula fermentum libero, eget iaculis felis porttitor eget. In condimentum leo magna, et egestas nisl bibendum gravida.

Praesent sed est vehicula, scelerisque diam et, ultrices neque. Vestibulum iaculis id risus sit amet posuere. Aliquam et risus vitae sem convallis tincidunt. Pellentesque ullamcorper ex a mi placerat, eu pulvinar risus porttitor. Vivamus a venenatis nisl. Morbi vestibulum ante et eros posuere, egestas fermentum sem scelerisque. Vestibulum ultrices in mi non lacinia. Proin ultricies luctus neque, ac auctor magna accumsan sit amet.

Donec eget mauris placerat, pharetra urna nec, porta est. Phasellus at magna vitae leo blandit tincidunt. Aenean mollis massa sed commodo fringilla. Donec enim nisl, mattis ac tincidunt eget, euismod sed dolor. Phasellus et mauris eget ex luctus vehicula sed vitae lacus. Aliquam sed viverra turpis, vel posuere risus. Mauris varius eu lacus a placerat. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Sed odio ipsum, pretium non sagittis aliquet, aliquet at dolor. Curabitur elementum metus et ex viverra, eget ullamcorper ex pulvinar. Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Vivamus tincidunt tellus mauris, eu tempus augue dignissim vitae.`;

export const loremL = `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc egestas varius sem eleifend tempor. Suspendisse sed mi viverra, fringilla velit vel, tincidunt metus. Cras hendrerit lacus eu nibh cursus, quis dapibus neque hendrerit. Suspendisse a bibendum lacus. Pellentesque congue sapien in nunc euismod, sed malesuada nisl mattis. Vestibulum fringilla sem mauris, sed sollicitudin dui ornare a. Maecenas ultrices aliquam turpis in cursus. Maecenas iaculis, nulla interdum venenatis rhoncus, nisl risus condimentum lacus, nec hendrerit tortor purus eu odio. Integer a nulla fermentum, iaculis felis nec, volutpat ligula. Praesent cursus imperdiet ante, ut eleifend ex laoreet vel. Quisque accumsan elit sed urna mollis, sed mattis lorem rutrum. Nullam auctor ex odio, ac feugiat purus faucibus varius. Aliquam metus leo, malesuada in est ut, feugiat fermentum libero.

Nulla vel aliquam lorem, a suscipit nulla. Nulla non aliquet leo. Aliquam placerat ornare lectus. Donec maximus volutpat diam varius euismod. Integer rhoncus luctus velit, eget semper ante ultrices sit amet. Phasellus eros urna, tristique in ultricies eget, tristique finibus enim. Pellentesque in lacinia augue. Quisque sit amet justo sagittis, maximus sem ut, accumsan justo. Vestibulum vitae pharetra velit, eget mollis quam.`;

export const loremS = `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc egestas varius sem eleifend tempor. Suspendisse sed mi viverra, fringilla velit vel, tincidunt metus. Cras hendrerit lacus eu nibh cursus, quis dapibus neque hendrerit. Suspendisse a bibendum lacus. Pellentesque congue sapien in nunc euismod, sed malesuada nisl mattis. Vestibulum fringilla sem mauris, sed sollicitudin dui ornare a. Maecenas ultrices aliquam turpis in cursus. Maecenas iaculis, nulla interdum venenatis rhoncus, nisl risus condimentum lacus, nec hendrerit tortor purus eu odio. Integer a nulla fermentum, iaculis felis nec, volutpat ligula. Praesent cursus imperdiet ante, ut eleifend ex laoreet vel. Quisque accumsan elit sed urna mollis, sed mattis lorem rutrum. Nullam auctor ex odio, ac feugiat purus faucibus varius. Aliquam metus leo, malesuada in est ut, feugiat fermentum libero.`;

export const loremXS = `Nulla vel aliquam lorem, a suscipit nulla. Nulla non aliquet leo. Aliquam placerat ornare lectus. Donec maximus volutpat diam varius euismod. Integer rhoncus luctus velit, eget semper ante ultrices sit amet. Phasellus eros urna, tristique in ultricies eget, tristique finibus enim. Pellentesque in lacinia augue. Quisque sit amet justo sagittis, maximus sem ut, accumsan justo. Vestibulum vitae pharetra velit, eget mollis quam.`;