import { TabList, Tabs, VStack, Tab, TabPanel, TabPanels } from "@chakra-ui/react"
import React from "react"
import { TOP_BAR_HEIGHT } from "../../constants/dimenstions"
import { ChatsTab } from "./chats-tab"
import { PeopleTab } from "./people-tab"
import config from "../../config.json"
import Scrollbars from "react-custom-scrollbars"

export const Chat = () => {

    if (config.branding.platformPackage === "full") {
        return (
            <VStack h={`100%`} maxH={`100%`} minW={"400px"} style={config.branding.colors.container}>
                <Tabs w="100%" h="100%">
                    <TabList w="100%" display="flex" justifyContent="center">
                        <Tab w="100%">Chat</Tab>
                        <Tab w="100%">{"Q&A"}</Tab>
                    </TabList>
                    <TabPanels h={`calc(100% - ${TOP_BAR_HEIGHT})`}>
                        <TabPanel h="100%">
                            <ChatsTab />
                        </TabPanel>
                        <TabPanel>
                            <PeopleTab />
                        </TabPanel>
                    </TabPanels>
                </Tabs>
            </VStack >
        )
    }

    return (
        <ChatsTab />
    )

}