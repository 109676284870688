import { Button, Heading, HStack, useMediaQuery, Text, VStack, useToast } from "@chakra-ui/react";
import React, { createRef, useRef } from "react"
import ReactHlsPlayer from "react-hls-player/dist";
import { useApiCall } from "../../hooks/use-api-call.hook"
import { User } from "../../stores/chat.store";
import { apiCall } from "../../util/api-helpers";
import { ContentPage } from "../content-page"

export const Admin = () => {

    const { data, loading, fetchData } = useApiCall({ url: "/people", method: "GET" });
    const [smallScreen] = useMediaQuery("(max-width: 1000px)");
    const toast = useToast();

    async function changeRole(userId: string, role: number) {
        const result = await apiCall({
            url: "/update-role", method: "POST", body: {
                userId, role
            }
        });

        toast({
            title: result?.success ? "Updated role" : "Failed to update",
            status: result?.success ? "success" : "error",
            isClosable: true
        });

        if (result?.success) fetchData();
    }

    return (
        <ContentPage title="Admin">
            {
                loading ? <Heading>Loading</Heading> :
                    <VStack w="100%">
                        <Heading w="100%" size="lg">Manage users</Heading>

                        {
                            data.map((user: User) => {
                                return (
                                    <HStack p="2" bg="blackAlpha.100" borderRadius="5px" w="100%" justifyContent="space-between">
                                        <Text>{user.name}</Text>
                                        <Button variant="miles" onClick={() => changeRole(user.userId, user.role === 0 ? 1 : 0)}>
                                            {user.role === 0 ? "Make" : "Remove"} admin
                                        </Button>
                                    </HStack>
                                )
                            })
                        }
                    </VStack>
            }

        </ContentPage>
    )
}