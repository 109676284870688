import { ChevronDownIcon } from "@chakra-ui/icons";
import { Text, Avatar, Button, HStack, Menu, MenuButton, MenuItem, MenuList } from "@chakra-ui/react";
import React, { FC } from "react";

export const Person: FC<{
    user: { name: string }
}> = ({ user }) => (

    <HStack w="100%" display="flex" justifyContent="space-between">

        {/* Details */}
        <HStack>
            <Avatar size="sm" name={user.name} />
            <Text>{user.name}</Text>
        </HStack>

        {/* Actions */}
        <Menu>
            <MenuButton bg={"blackAlpha.100"} as={Button} rightIcon={<ChevronDownIcon />}>Actions</MenuButton>
            <MenuList>
                <MenuItem>Invite to network session</MenuItem>
            </MenuList>
        </Menu>
    </HStack>


)