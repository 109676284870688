import { VStack } from "@chakra-ui/layout"
import { IconButton, Tooltip, Text } from "@chakra-ui/react"
import React from "react"
import { FaFilm, FaHandshake, FaHome, FaLayerGroup } from "react-icons/all"
import { Link } from "react-router-dom"
export const SideBar = () => {
    return (
        <VStack spacing="7" h="100%" maxH="100%" padding="7" display="flex" overflowY="auto" backgroundColor={"blackAlpha.100"}>

            <Link to="/" href="/">
                <VStack>
                    <Tooltip placement="right" hasArrow label="Event's Reception">
                        <IconButton size="lg" icon={<FaHome />} aria-label="Go home" backgroundColor={"blackAlpha.100"} />
                    </Tooltip>
                    <Text>Reception</Text>
                </VStack>
            </Link>

            <Link to="/stage" href="/stage">
                <VStack>
                    <Tooltip placement="right" hasArrow label="Event's Main Stage">
                        <IconButton size="lg" icon={<FaFilm />} aria-label="Go to the main stage" backgroundColor={"blackAlpha.100"} />
                    </Tooltip>
                    <Text>Stage</Text>
                </VStack>
            </Link>

            <Link to="/sessions" href="/sessions">
                <VStack>
                    <Tooltip placement="right" hasArrow label="Event's Sessions">
                        <IconButton size="lg" icon={<FaLayerGroup />} aria-label="Go to sessions" backgroundColor={"blackAlpha.100"} />
                    </Tooltip>
                    <Text>Sessions</Text>
                </VStack>
            </Link>

            <Link to="/networking" href="/networking">
                <VStack>
                    <Tooltip placement="right" hasArrow label="Network with people">
                        <IconButton size="lg" icon={<FaHandshake />} aria-label="Go to networking" backgroundColor={"blackAlpha.100"} />
                    </Tooltip>
                    <Text>Networking</Text>
                </VStack>
            </Link>
        </VStack >
    )
}