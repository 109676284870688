import { Text, Button, Drawer, DrawerOverlay, DrawerContent, DrawerCloseButton, DrawerHeader, DrawerBody, Input, DrawerFooter, UseDisclosureProps, Avatar, Tag, TagLabel, Divider, Heading, HStack, PinInput, PinInputField } from "@chakra-ui/react"
import React, { FC } from "react"


export const ProfileDrawer: FC<{
    drawerProps: UseDisclosureProps
}> = ({ drawerProps }) => {

    return (

        <Drawer
            isOpen={drawerProps.isOpen!}
            placement="top"
            onClose={drawerProps.onClose!}
        >
            <DrawerOverlay>

                <DrawerContent>
                    <DrawerCloseButton />
                    <DrawerHeader>Profile</DrawerHeader>

                    <DrawerBody>
                        <Tag size="lg" h="10" mb="4" colorScheme="blue" borderRadius="full">
                            <Avatar
                                size="xs"
                                name="Bas Wilson"
                                ml={-1}
                                mr={2}
                            />
                            <TagLabel>Bas Wilson #3333</TagLabel>
                        </Tag>
                        <Divider />
                        <Heading mt="4" mb="2" size="sm">Change name</Heading>
                        <Input placeholder="Your name..." value="Bas Wilson" />

                        <Heading mt="4" mb="2" size="sm">Change pincode</Heading>
                        <Text mb="2">Old pincode</Text>
                        <HStack>
                            <PinInput type="number" mask>
                                <PinInputField />
                                <PinInputField />
                                <PinInputField />
                                <PinInputField />
                            </PinInput>
                        </HStack>
                        <Text mt="4" mb="2">New pincode</Text>
                        <HStack>
                            <PinInput type="number" mask>
                                <PinInputField />
                                <PinInputField />
                                <PinInputField />
                                <PinInputField />
                            </PinInput>
                        </HStack>
                    </DrawerBody>

                    <DrawerFooter>
                        <Button variant="outline" mr={3} onClick={drawerProps.onClose}>Cancel</Button>
                        <Button colorScheme="blue">Save</Button>
                    </DrawerFooter>
                </DrawerContent>
            </DrawerOverlay>
        </Drawer >
    )
}