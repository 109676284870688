import { Badge, Box, Circle, Heading, Text, useMediaQuery } from "@chakra-ui/react";
import { observer } from "mobx-react-lite";
import React, { createRef, useContext, useEffect } from "react"
import ReactHlsPlayer from "react-hls-player/dist";
import { FaEye } from "react-icons/fa";
import { useApiCall } from "../../hooks/use-api-call.hook"
import { stores } from "../../styled/stores";
import { ContentPage } from "../content-page"
import { ViewerCount } from "../viewer-count";

export const Stage = () => {

    const { data, loading } = useApiCall({ url: "/stage", method: "GET" });
    const [smallScreen] = useMediaQuery("(max-width: 1000px)");
    const playerRef = createRef<HTMLVideoElement>();
    return (
        <ContentPage justifyContent={smallScreen ? "flex-start" : "center"} title="Stage">
            {
                loading ? <Heading>Loading</Heading> :
                    <div style={{ width: "100%", position: "relative", maxHeight: "100%", overflow: "none" }} >
                        <ReactHlsPlayer
                            style={{ outline: "none", borderRadius: "5px", maxHeight: "100%" }}
                            width="100%"
                            muted={false}
                            playerRef={playerRef}
                            autoPlay={true}
                            controls={true}
                            src={data.streamUrl} />
                        <ViewerCount />
                    </div>
            }
        </ContentPage>
    )
}