import React, { Component, FC, useState } from "react"
import { Heading, HStack, VStack, Text, Badge } from "@chakra-ui/layout"
import { Avatar } from "@chakra-ui/avatar"
import { ChatMessage as ChatMessageI } from "../../../stores/chat.store"
import { getUid } from "../../../util/state-helpers"
import config from "../../../config.json"
import { Tag } from "@chakra-ui/tag"
import { useMediaQuery } from "@chakra-ui/react"

export const ChatMessage: FC<{
    message: ChatMessageI
    chatBubble?: boolean
    picture?: boolean
    time?: boolean
    name?: boolean
}> = ({ children, message, chatBubble, picture, time, name }) => {
    const date = new Date(message.timestamp)
    const isUser = getUid() === message.user.userId
    const [smallScreen] = useMediaQuery("(max-width: 1000px)")
    const [showMenu, setShowMenu] = useState(smallScreen)

    return (
        <HStack
            paddingTop={message.votes !== 0 ? "10px" : "0"}
            onMouseOver={() => setShowMenu(true)}
            onMouseLeave={() => setShowMenu(smallScreen)}
            justifyContent={chatBubble ? (isUser ? "flex-end" : "flex-start") : "flex-start"}
            maxW={"100%"}
            w={"100%"}
        >
            {chatBubble || !picture ? null : <Avatar name={message.user.name} />}
            {showMenu && isUser ? children : null}

            <VStack
                position='relative'
                padding={chatBubble ? "2" : "0"}
                bg={
                    config.branding.colors.chat[isUser ? "bubbleSent" : "bubbleReceived"].background
                }
                color={config.branding.colors.chat[isUser ? "bubbleSent" : "bubbleReceived"].text}
                borderRadius={config.branding.colors.container.borderRadius}
                maxW='80%'
                w='fit-content'
            >
                <HStack padding='0' w='100%'>
                    {name ? (
                        message.user.role ? (
                            <strong>
                                <Badge
                                    color='black'
                                    bg={config.branding.colors.container.backgroundColor}
                                >
                                    Admin
                                </Badge>
                                &nbsp;&nbsp;{message.user.name}, {message.user.org}
                            </strong>
                        ) : (
                            <Heading size='sm'>
                                {message.user.name}, {message.user.org}{" "}
                            </Heading>
                        )
                    ) : null}
                    {time ? (
                        <Text>
                            {date.getHours()}:{date.getMinutes()}
                        </Text>
                    ) : null}
                </HStack>
                <HStack
                    marginTop={!name && !time ? "0 !important" : "initial"}
                    padding='0'
                    w='100%'
                    overflowX='hidden'
                >
                    <Text marginTop={!name && !time ? "0 !important" : "initial"}>
                        {message.message}
                    </Text>
                </HStack>

                {message.votes ? (
                    <Tag
                        borderRadius={"50%"}
                        position='absolute'
                        right={isUser ? "unset" : "-10px"}
                        top='-20px'
                        left={!isUser ? "unset" : "-10px"}
                    >
                        {message.votes}
                    </Tag>
                ) : null}
            </VStack>

            {showMenu && !isUser ? children : null}
        </HStack>
    )
}
