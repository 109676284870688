import { useEffect, RefObject, UIEventHandler } from "react";
import Scrollbars from "react-custom-scrollbars";
import { ChatStore } from "../stores/chat.store";

let scrolled = false;

export const useScrollToBottom = (ref: RefObject<Scrollbars>, store: ChatStore) => {

    function scrollDown(override?: boolean) {
        if (!ref.current) return;
        const el = ref.current;
        if (!scrolled || override === true)
            el.scrollToBottom();
    }

    function onScroll() {

        const el = ref.current;
        if (!el) return;
        if (el.getScrollHeight() - el.getScrollTop() === el.getClientHeight()) {
            scrolled = false;
        } else {
            scrolled = true;
        }
    }

    useEffect(() => {
        store.setScroller(scrollDown);
        scrollDown();
    });

    return {
        scrollDown,
        onScroll
    }
};