import { VStack, HStack, useMediaQuery } from '@chakra-ui/react';
import React, { useContext, useEffect } from 'react';
import { BrowserRouter as Router } from "react-router-dom";
import { Routes } from './components/routes';
import { Chat } from './components/chat-panel';
import { SideBar } from './components/sidebar';
import { TopBar } from './components/topbar';
import { Signup } from './components/pages/signup';
import { Loading } from './components/pages/loading';
import { useProfileHook } from './hooks/use-profile.hook';
import { CustomVStackVW, CustomHStack, CustomVStack } from './styled/global-styles';
import { TOP_BAR_HEIGHT } from './constants/dimenstions';
import { useWsHook } from './hooks/use-ws.hook';
import { stores } from './styled/stores';
import config from "./config.json";
import { observer } from 'mobx-react-lite';

const App = observer(() => {

  const chatStore = useContext(stores.chatStore);
  const { loading } = useProfileHook(true);
  const [smallScreen] = useMediaQuery("(max-width: 1000px)");

  const { connected, socket, connect } = useWsHook("", [
    {
      event: "streamStats",
      method: chatStore.streamStatsUpdate
    },
    {
      event: "chatMessage",
      method: chatStore.addMessage
    },
    {
      event: "messageUpdate",
      method: chatStore.messageUpdated
    },
    {
      event: "greenscreenMessage",
      method: chatStore.setGreenscreenMessage
    }
  ]);

  if (socket) {
    chatStore.assignSocket(socket);
  }

  if (loading) {
    return <Loading />;
  }

  return (

    <Router>
      {chatStore.user && chatStore.nameConfirmed || chatStore.user?.userId === "greenscreen" ?
        // Web App
        (
          <VStack w="100vw" h={"100vh"}>

            {/* Topbar */}
            <TopBar connected={connected} user={chatStore.user} />

            {
              smallScreen ?
                <VStack mt="0px !important" w="100%" h="100%" style={config.branding.colors.main}>

                  {/* Sidebar */}
                  {config.branding.eventType === "full" ? <SideBar /> : null}

                  {/* Center content */}
                  <VStack w="100%" mb="20px">
                    <Routes />
                  </VStack>

                  {/* Chat */}
                  <Chat />

                </VStack> :

                <HStack mt="0px !important" w="100%" h="100%" style={config.branding.colors.main}>

                  {/* Sidebar */}
                  {config.branding.eventType === "full" ? <SideBar /> : null}

                  {/* Center content */}
                  <VStack w="100%" h="100%" overflowY="auto">
                    <Routes />
                  </VStack>

                  {/* Chat */}
                  <Chat />

                </HStack>
            }


          </VStack>
        )
        : <Signup wsConnect={connect} />
      }
    </Router>
  );
})

export default App;