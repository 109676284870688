export const getUid = () => {
    return localStorage.getItem("uid");
}

export const setUid = async (username: string) => {
    localStorage.setItem("uid", username);
    return true;
}

export const signOut = () => {
    localStorage.clear();
    window.location.reload();
}