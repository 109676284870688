import { TabPanel, Tab, TabList, TabPanels, Tabs, useMediaQuery } from "@chakra-ui/react"
import React from "react"
import { ChatProvider } from "../chat-provider"
import config from "../../../config.json"

export const ChatsTab = () => {
    // if (config.branding.platformPackage === "full") {
    const [smallScreen] = useMediaQuery("(max-width: 1000px)")

    return (
        <Tabs
            mt='0 !important'
            w={smallScreen ? "100%" : "350px"}
            variant='soft-rounded'
            colorScheme='orange'
            alignSelf='flex-start'
            h='calc(100% - 20px)'
        >
            <TabList w='100%' display='flex' justifyContent='center'>
                <Tab color='whiteAlpha.800'>Chat</Tab>
                <Tab color='whiteAlpha.800'>Q&A</Tab>
            </TabList>
            <TabPanels h={smallScreen ? "calc(100% - 20px)" : "100%"}>
                <TabPanel h='100%' paddingX='0'>
                    <ChatProvider chatId='event' description='Start of the chat' />
                </TabPanel>
                <TabPanel h='100%' paddingX='0'>
                    <ChatProvider
                        chatId='qa'
                        description='Start of the questions and answers chat'
                    />
                </TabPanel>
            </TabPanels>
        </Tabs>
    )
    // } else {
    //     return (
    //         <ChatProvider title="Chat" chatId="event" />
    //     )
    // }
}
