import { VStack, Heading, useMediaQuery } from "@chakra-ui/react"
import React, { FC } from "react"
import { TOP_BAR_HEIGHT } from "../../constants/dimenstions"
import config from "../../config.json";


export const ContentPage: FC<{
    title: string,
    justifyContent?: string;
}> = ({ children, title, justifyContent }) => {

    const [smallScreen] = useMediaQuery("(max-width: 1000px)");

    return (
        <VStack justifyContent={justifyContent || "flex-start"} h={smallScreen ? "unset" : `calc(100vh - 40px - ${TOP_BAR_HEIGHT})`} maxH={`calc(${smallScreen ? "50vh" : "100vh"} - 40px - ${TOP_BAR_HEIGHT})`} w="100%" pr={smallScreen ? "0" : "20px"}>
            {config.branding.eventType === "full" ? <Heading w="100%">{title}</Heading> : null}
            {children}
        </VStack>
    )
}