import { API_BASE } from "../constants/api"
import { isGreenscreen } from "./query-helpers"
import { getUid } from "./state-helpers"

export interface Request {
    url: string;
    method: "GET" | "POST" | "PUT" | "DELETE";
    body?: object
}

export const apiCall = async (request: Request) => {
    try {
        const response = await fetch(API_BASE + request.url, {
            method: request.method,
            headers: {
                "Authorization": `bearer ${isGreenscreen() ? "greenscreen" : getUid()}`,
                "Content-Type": "application/json"
            },
            body: request.method === "GET" ? null : JSON.stringify(request.body || {})
        });

        if (response.status === 200 || response.status === 201) {
            const json = await response.json();
            console.log("REQUEST RES", json);
            return json;
        } else {
            console.log("RAW RESPONSE", (await response.text()));
            return null
        }

    } catch (error) {
        console.error("Error occured while calling API", error);
    }
}