import { Input } from "@chakra-ui/input"
import { Heading, VStack } from "@chakra-ui/layout"
import {
    HStack,
    IconButton,
    Menu,
    MenuButton,
    MenuItem,
    MenuList,
    ScaleFade,
    Tooltip,
    useDisclosure,
    useMediaQuery,
    useToast,
} from "@chakra-ui/react"
import { observer } from "mobx-react-lite"
import React, { createRef, FC, useContext, useState } from "react"
import { MdSend } from "react-icons/md"
import { stores } from "../../../styled/stores"
import { ChatMessage } from "../chat-message"
import config from "../../../config.json"
import Scrollbars from "react-custom-scrollbars"
import { useScrollToBottom } from "../../../hooks/use-scroll-to-bottom.hook"
import { useApiCall } from "../../../hooks/use-api-call.hook"
import { FiMoreHorizontal } from "react-icons/fi"
import { BiDownvote, BiUpvote } from "react-icons/bi"
import {
    FaArrowDown,
    FaChevronDown,
    FaPause,
    FaPlay,
    FaScroll,
    FaStream,
    FaSync,
    FaThumbsUp,
    FaTrain,
    FaVolumeMute,
    FaVolumeUp,
} from "react-icons/fa"
import { isGreenscreen } from "../../../util/query-helpers"
import styled from "styled-components"
import { FcFlashOff, FcFlashOn } from "react-icons/fc"

export const ChatProvider: FC<{
    chatId: string
    title?: string
    description?: string
}> = observer(({ chatId, title, description }) => {
    const ref = createRef<Scrollbars>()
    const [message, setMessage] = useState("")
    const [chatTimeout, setChatTimeout] = useState(false)
    const chatStore = useContext(stores.chatStore)
    const { scrollDown, onScroll } = useScrollToBottom(ref, chatStore)

    useApiCall({ method: "GET", url: "/messages" }, true, (messages: any[]) => {
        chatStore.setMessages(messages)
    })
    const [smallScreen] = useMediaQuery("(max-width: 1000px)")
    const toast = useToast()

    const sendMessage = () => {
        if (chatTimeout) {
            return toast({
                title: "Message not sent yet!",
                description: "Please wait 2 seconds in between sending messages",
                status: "error",
                duration: 5000,
                isClosable: true,
            })
        }
        if (message.length > 0 && !chatTimeout) {
            chatStore.socket?.emit("chatMessage", { message: message, type: chatId })
            setMessage("")
            setChatTimeout(true)
            scrollDown(true)
            setTimeout(() => {
                setChatTimeout(false)
            }, 2000)
        }
    }

    const vote = (messageId: string) => {
        chatStore.socket?.emit("vote", { messageId })
    }

    const sendToStream = (messageId: string) => {
        chatStore.socket?.emit("showOnStream", { messageId, vote })
    }

    return (
        <VStack
            h={"100%"}
            style={
                config.branding.platformPackage !== "full"
                    ? Object.assign(
                          {
                              padding: "20px",
                              minWidth: smallScreen ? "100px" : "350px",
                              width: smallScreen ? "100%" : "auto",
                          },
                          config.branding.colors.container
                      )
                    : {}
            }
            w={config.branding.platformPackage === "full" ? "100%" : "30%"}
            display='flex'
            justifyContent='space-between'
        >
            <HStack w='100%' justifyContent='center' alignContent='center'>
                <Tooltip label='Scroll to the bottom of chat' placement='left'>
                    <IconButton
                        onClick={() => scrollDown(true)}
                        _active={{ background: "rgba(255,255,255,0.1)" }}
                        _hover={{ background: "rgba(255,255,255,0.1)" }}
                        background={"transparent"}
                        aria-label='Scroll down'
                        icon={<FaArrowDown />}
                    />
                </Tooltip>
            </HStack>
            <Scrollbars onScroll={onScroll} ref={ref} style={{ height: `100%` }}>
                <VStack w='100%' h='100%'>
                    <ChatMessage
                        chatBubble={true}
                        picture={false}
                        key={"message_start_" + chatId}
                        message={{
                            message: description || "Start of conversation",
                            type: chatId,
                            messageId: "bleh",
                            timestamp: Date.now(),
                            votes: 0,
                            voters: [],
                            user: {} as any,
                            showOnStreamUntil: 0,
                        }}
                    />
                    {chatStore.chatMessages
                        .filter((message) => message.type === chatId)
                        .map((message) => {
                            return (
                                <ChatMessage
                                    chatBubble
                                    name
                                    picture={false}
                                    key={message.messageId}
                                    message={message}
                                >
                                    {chatStore.user?.role === 0 ? (
                                        <IconButton
                                            onClick={() => vote(message.messageId)}
                                            bg='transparent'
                                            opacity={
                                                message.voters.includes(chatStore.user?.userId)
                                                    ? "1.0"
                                                    : "0.5"
                                            }
                                            _active={{ bg: "transparant" }}
                                            _hover={{ bg: "transparant", opacity: 1 }}
                                            icon={<FaThumbsUp color='black' />}
                                            aria-label='Thumbs up'
                                        />
                                    ) : (
                                        <Menu isLazy={true}>
                                            <Tooltip
                                                label='Vote a message up or down'
                                                placement='left'
                                            >
                                                <MenuButton
                                                    _active={{
                                                        background: "rgba(0,0,0,0.1)",
                                                    }}
                                                    _hover={{ background: "rgba(0,0,0,0.2)" }}
                                                    h='fit-content'
                                                    p='0'
                                                    w='fit-content'
                                                    backgroundColor={"transparent"}
                                                    as={IconButton}
                                                    icon={<FiMoreHorizontal color='black' />}
                                                />
                                            </Tooltip>
                                            <MenuList
                                                color={
                                                    config.branding.colors.chat.bubbleReceived.text
                                                }
                                            >
                                                <MenuItem
                                                    onClick={() => vote(message.messageId)}
                                                    justifyContent='space-between'
                                                >
                                                    <span>Upvote</span>
                                                    <BiUpvote
                                                        color={
                                                            config.branding.colors.button.background
                                                        }
                                                    />
                                                </MenuItem>
                                                {chatStore.user?.role! > 0 ? (
                                                    <MenuItem
                                                        onClick={() =>
                                                            sendToStream(message.messageId)
                                                        }
                                                        justifyContent='space-between'
                                                    >
                                                        <span>Send to Stream</span>
                                                        <FaStream
                                                            color={
                                                                config.branding.colors.button
                                                                    .background
                                                            }
                                                        />
                                                    </MenuItem>
                                                ) : null}
                                            </MenuList>
                                        </Menu>
                                    )}
                                </ChatMessage>
                            )
                        })}
                </VStack>
            </Scrollbars>

            <HStack w='100%' spacing='0' mt='20px !important'>
                <Input
                    borderRightRadius='0'
                    value={message}
                    onKeyDown={(e) => {
                        if (e.key === "Enter") {
                            sendMessage()
                        }
                    }}
                    onChange={(e) => setMessage(e.target.value)}
                    style={config.branding.colors.input}
                    _focus={config.branding.colors.input}
                    placeholder='Send a message...'
                />
                <Tooltip label='Send a public message' placement='left'>
                    <IconButton
                        h='100%'
                        variant='miles'
                        borderLeftRadius='0'
                        disabled={!message.length || chatTimeout}
                        icon={<MdSend />}
                        aria-label='Send message'
                        onClick={sendMessage}
                    />
                </Tooltip>
            </HStack>
        </VStack>
    )
})

const GreenScreen = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #00b140;
    z-index: 2000;
`
