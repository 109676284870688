import { useContext, useEffect, useState } from "react";
import { stores } from "../styled/stores";
import { apiCall } from "../util/api-helpers";

export const useProfileHook = (loadingState: boolean) => {

    const chatStore = useContext(stores.chatStore);
    const [loading, setLoading] = useState(loadingState);
    const [user, setUser] = useState<any | null>(null);

    useEffect(() => {
        const fetchData = async () => {
            const result = await apiCall({ url: "/profile", method: "GET" });
            setUser(result);
            chatStore.setUser(result);
            setLoading(false)
        };

        fetchData();
    }, []);

    return { user, loading };
};