import io from 'socket.io-client';
import { useEffect, useState } from "react";
import { WS_API_BASE } from "../constants/api";
import { getUid } from "../util/state-helpers";
import { isGreenscreen } from '../util/query-helpers';

export const useWsHook = (namespace: string, events?: { event: string, method: Function }[]) => {

    const [connected, setConnected] = useState(false);
    const [socket, setSocket] = useState<SocketIOClient.Socket | undefined>();

    const connect = () => {
        if (socket && socket.connected) return;

        const newSocket = io((WS_API_BASE) + namespace + `?token=${isGreenscreen() ? "greenscreen" : getUid()}`, {
            transports: ['websocket']
        })

        newSocket.on("disconnect", () => {
            console.log("WS disconnected", namespace);
            setConnected(false);
        })
        newSocket.on("connect", () => {
            console.log("WS connected", namespace);
            setConnected(true);
        })

        events?.forEach(event => {
            newSocket.on(event.event, event.method);
        });

        setSocket(newSocket);
    };

    useEffect(() => {
        connect();
    }, []);

    return { connected, socket, connect };
};