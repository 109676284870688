import { Input, VStack } from "@chakra-ui/react"
import React, { useState } from "react"
import { useApiCall } from "../../../hooks/use-api-call.hook"
import { Loading } from "../../pages/loading"
import { Person } from "./person"

export const PeopleTab = () => {

    const [query, setQuery] = useState("");
    const people = useApiCall({ method: "GET", url: "/people" })

    if (people.loading) {
        return <Loading />;
    }

    return (
        <VStack width="100%">
            <Input bg={"blackAlpha.100"} variant="filled" onChange={(e) => setQuery(e.target.value)} placeholder="Find a person..." />
            {people.data.filter((user: any) => {
                return user.name.toLowerCase().includes(query.toLowerCase())
            }).map((user: any, index: number) => {
                return <Person key={index} user={user} />
            })}
        </VStack>
    )
}