export function ScrollToBottom(selector: string) {
    const el = document.querySelector(selector)

    if (!el) return

    const scrollHeight = el.scrollHeight
    const height = el.clientHeight
    const maxScrollTop = scrollHeight - height

    el.scrollTop = maxScrollTop > 0 ? maxScrollTop : 0
}

export const isGreenscreen = () => window.location.pathname === "/greenscreen";