import { VStack, HStack, Heading, Text, Badge, SimpleGrid, Button } from "@chakra-ui/react"
import React from "react"
import { FaFacebook, FaTwitter } from "react-icons/fa"
import { loremL } from "../../constants/lorem"
import { ContentPage } from "../content-page"
import { Sponsor } from "../sponsor"

export const Reception = () => {
    return (
        <ContentPage title="Reception">
            <VStack w="100%" >
                <Text mb="2">{loremL}</Text>

                <HStack w="100%">
                    <Button colorScheme="facebook" leftIcon={<FaFacebook />}>Facebook </Button>
                    <Button colorScheme="twitter" leftIcon={<FaTwitter />}>Twitter</Button>
                </HStack>


                <VStack w="100%" display="flex" alignItems="flex-start" spacing="4">
                    <Heading mt="4" w="100%">Sponsors</Heading>
                    <Badge mt="4" mb="4" p="2" colorScheme="yellow">Gold sponsors</Badge>
                    <SimpleGrid minChildWidth="240px" spacing="40px" w="100%" justifyContent="flex-start">
                        {
                            new Array(3).fill(0, 0, 6).map((e, i) => {
                                return <Sponsor key={i} name="MWC" image="/logo.svg" url="https://www.mwcbarcelona.com/" />
                            })
                        }
                    </SimpleGrid>
                </VStack>

                <VStack w="100%" display="flex" alignItems="flex-start">
                    <Badge mt="8" mb="4" p="2" colorScheme="gray">Silver sponsors</Badge>
                    <SimpleGrid minChildWidth="140px" spacing="40px" w="100%" justifyContent="flex-start">
                        {
                            new Array(4).fill(0, 0, 6).map((e, i) => {
                                return <Sponsor key={i} name="MWC" image="/logo.svg" url="https://www.mwcbarcelona.com/" />
                            })
                        }
                    </SimpleGrid>
                </VStack>

                <VStack w="100%" display="flex" alignItems="flex-start">
                    <Badge mt="8" mb="4" p="2" colorScheme="orange">Bronze sponsors</Badge>
                    <SimpleGrid minChildWidth="120px" spacing="40px" w="100%" justifyContent="flex-start">
                        {
                            new Array(6).fill(0, 0, 6).map((e, i) => {
                                return <Sponsor key={i} name="MWC" image="/logo.svg" url="https://www.mwcbarcelona.com/" />
                            })
                        }
                    </SimpleGrid>
                </VStack>
            </VStack>
        </ContentPage>
    )
}