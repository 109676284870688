import React, { useContext } from "react";
import { Switch, Route } from "react-router-dom";
import { Networking } from "../pages/networking";
import { Reception } from "../pages/reception";
import { Sessions } from "../pages/sessions";
import { Stage } from "../pages/stage";
import config from "../../config.json";
import { Greenscreen } from "../pages/greenscreen";
import { observer } from "mobx-react-lite";
import { stores } from "../../styled/stores";
import { Admin } from "../pages/admin";

export const Routes = observer(() => {

    const store = useContext(stores.chatStore);

    if (config.branding.platformPackage === "full") {
        return (
            <Switch>
                <Route exact path="/">
                    <Reception />
                </Route>

                <Route exact path="/stage">
                    <Stage />
                </Route>

                <Route exact path="/sessions">
                    <Sessions />
                </Route>

                <Route exact path="/networking">
                    <Networking />
                </Route>

                <Route exact path="/greenscreen">
                    <Greenscreen />
                </Route>
            </Switch>
        )
    } else {
        return (
            <Switch>
                <Route exact path="/">
                    <Stage />
                </Route>

                <Route exact path="/greenscreen">
                    <Greenscreen />
                </Route>

                {
                    store.user?.role === 1 ?
                        <Route exact path="/manage">
                            <Admin />
                        </Route>
                        : null
                }
            </Switch>
        )
    }
})