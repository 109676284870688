import { makeAutoObservable, action, observable } from 'mobx';
import { isGreenscreen, ScrollToBottom } from '../util/query-helpers';

let greenscreenTimeout: NodeJS.Timeout;

export class ChatStore {

    @observable
    public chatMessages: ChatMessage[] = [];
    public greenscreenMessage: ChatMessage | null = null;
    public socket: SocketIOClient.Socket | null = null;
    public scroller: any;
    public user: User | null = null;
    public nameConfirmed = false;
    public interacted = false;
    public streamStats = { viewerCount: 0 }

    constructor() {
        makeAutoObservable(this);
    }

    @action
    setScroller = (scroller: any) => {
        this.scroller = scroller;
    }

    @action
    setNameConfirmed = () => {
        this.nameConfirmed = true;
    }

    @action
    setInteracted = () => {
        this.interacted = true;
    }

    @action
    assignSocket = (socket: SocketIOClient.Socket) => {
        this.socket = socket;
    }

    @action
    setUser = (user: User) => {
        this.user = user;
    }

    @action
    addMessage = (chatMessage: ChatMessage) => {
        this.chatMessages.push(chatMessage);
    }

    @action
    messageUpdated = (chatMessage: ChatMessage) => {
        const index = this.chatMessages.findIndex(m => m.messageId === chatMessage.messageId);
        if (index !== -1) this.chatMessages[index] = chatMessage;
    }

    @action
    setMessages = (chatMessages: ChatMessage[]) => {
        this.chatMessages = chatMessages;
        if (this.scroller) {
            setTimeout(() => {
                this.scroller()
            }, 500);
        }
    }

    @action
    setGreenscreenMessage = (chatMessage: ChatMessage) => {
        this.greenscreenMessage = chatMessage;
        clearTimeout(greenscreenTimeout);
        greenscreenTimeout = setTimeout(() => {
            this.greenscreenMessage = null;
        }, 30000);
    }

    @action
    streamStatsUpdate = (stats: { viewerCount: number }) => {
        console.log(stats)
        this.streamStats = stats;
    }
}

export interface ChatMessage {
    messageId: string;
    type: string;
    message: string;
    timestamp: number;
    votes: number;
    voters: string[];
    user: User;
    showOnStreamUntil: number;
}

export interface User {
    name: string;
    pin: string;
    userId: string;
    role: number;
    org: string;
    ordNr?: string;
}