import { Heading, VStack } from "@chakra-ui/layout"
import { SlideFade, useDisclosure } from "@chakra-ui/react"
import { observer } from "mobx-react-lite"
import React, { useContext, useEffect } from "react"
import styled from "styled-components"
import { stores } from "../../styled/stores"

export const Greenscreen = observer(() => {
    const store = useContext(stores.chatStore);

    return (
        <GreenScreen>
            {
                <MessageTransition show={store.greenscreenMessage ? true : false}>
                    <VStack borderRadius="5px" maxW="1000px" w="fit-content" padding="20px" color="black" background="white" boxShadow="sm">
                        <Heading w="100%" color="blackAlpha.500" size="sm">QUESTION FROM THE AUDIENCE</Heading>
                        <Heading w="100%" size="md">“{store.greenscreenMessage?.message}”</Heading>
                        <Heading w="100%" color="blackAlpha.500" size="xs">{store.greenscreenMessage?.user.name}, {store.greenscreenMessage?.user.org}</Heading>
                    </VStack>
                </MessageTransition>
            }
        </GreenScreen>
    )
})

const GreenScreen = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #00b140;
    z-index: 2000;
`
const MessageTransition = styled.div<{ show: boolean }>`
    left: ${props => props.show ? "100px" : "-100%"};
    transition: all 0.2s;
    position: fixed;
    bottom: 100px;
    margin-right: 100px;
`