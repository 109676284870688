import { Text } from "@chakra-ui/react"
import React from "react"
import { ContentPage } from "../content-page"

export const Networking = () => {
    return (
        <ContentPage title="Networking">
            <Text w="100%">
                Networking allows you to exchange contact information with other attendees.
            </Text>
            <Text w="100%">
                To start a network session you can click on the People tab and look for the person you want to network with, then click on actions and then Invite to network session.
            </Text>
        </ContentPage>
    )
}