import { Badge, Flex, Heading } from "@chakra-ui/layout"
import {
    Button,
    HStack,
    Avatar,
    Image,
    Menu,
    MenuButton,
    MenuItem,
    MenuList,
    useDisclosure,
    Spinner,
    Tag,
    useMediaQuery,
} from "@chakra-ui/react"
import React, { FC } from "react"
import { ChevronDownIcon } from "@chakra-ui/icons"
import { ProfileDrawer } from "../drawers/profile-drawer"
import { signOut } from "../../util/state-helpers"
import config from "../../config.json"
import { FaExternalLinkAlt } from "react-icons/fa"
import { useHistory } from "react-router"

export const TopBar: FC<{
    connected: boolean
    user: any
}> = ({ connected, user }) => {
    const h = useHistory()
    const profileDrawer = useDisclosure()
    const [smallScreen] = useMediaQuery("(max-width: 1000px)")

    return (
        <HStack
            spacing='10'
            w='100%'
            padding='4'
            m='0'
            display='flex'
            justifyContent='space-between'
            backgroundColor={config.branding.colors.container.backgroundColor}
        >
            <HStack>
                {smallScreen ? null : (
                    <Flex flexWrap='wrap'>
                        {config.branding.logos.map((logo) => {
                            return <Image maxH='12' src={logo} />
                        })}
                    </Flex>
                )}

                <Tag
                    hidden={smallScreen}
                    borderRadius={"48px"}
                    style={{ marginLeft: "15px" }}
                    paddingX={3}
                    paddingY={2}
                    color={config.branding.colors.main.color}
                    background={config.branding.colors.schemes.green}
                >
                    {config.branding.eventType}
                </Tag>
            </HStack>

            <HStack>
                {config.misc.showConnectedBadge ? (
                    <Badge colorScheme={connected ? "green" : "red"}>
                        {connected ? "Connected" : "Disconnected"}
                    </Badge>
                ) : null}
                {user && config.branding.eventType === "full" ? (
                    <Menu>
                        <MenuButton
                            backgroundColor={"transparent"}
                            as={Button}
                            rightIcon={<ChevronDownIcon />}
                        >
                            <Avatar size='xs' name={user.name} />
                        </MenuButton>
                        <MenuList>
                            <MenuItem onClick={profileDrawer.onOpen}>Profile</MenuItem>
                            <MenuItem onClick={signOut} color='red.500'>
                                Sign out
                            </MenuItem>
                        </MenuList>
                    </Menu>
                ) : config.branding.eventType === "full" ? (
                    <Spinner />
                ) : (
                    <>
                        {config.misc.externalLink ? (
                            <a href={config.misc.externalLink} target='blank'>
                                <Button variant='miles' rightIcon={<FaExternalLinkAlt />}>
                                    View challenge
                                </Button>
                            </a>
                        ) : null}
                        {user.role === 1 ? (
                            <Button onClick={() => h.push("/manage")} variant='miles'>
                                Manage
                            </Button>
                        ) : null}
                        {user.role === 1 ? (
                            <Button onClick={() => h.push("/")} variant='miles'>
                                Stage
                            </Button>
                        ) : null}
                    </>
                )}
                <Heading
                    hidden={smallScreen}
                    maxW={["200px", "400px"]}
                    style={{ marginLeft: "15px", textAlign: "right" }}
                    color={config.branding.colors.main.backgroundColor}
                    size='sm'
                >
                    {config.branding.tagLine}
                </Heading>
            </HStack>

            {/* Drawers */}
            {config.branding.eventType === "full" ? (
                <ProfileDrawer drawerProps={profileDrawer} />
            ) : null}
        </HStack>
    )
}
